
import { defineComponent } from 'vue';
import ResourcesItem from "@/components/ResourcesItem.vue"
import {IResourcesItem, storeKey} from "@/store"
import PageView from "@/components/PageView.vue"
import {useStore} from "vuex"

export default defineComponent({
  name: 'Students',


  data() {
    return {
      store: useStore(storeKey)
    }
  },

  mounted() {

    console.log( "students: ", this.store.state.students )

  },

  computed: {
    studentsItems(): IResourcesItem[] | null {
      return this.store.state.students?.items || null
    }
  },

  components: {
    PageView,
    ResourcesItem
  },
});
