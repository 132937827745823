import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0cd99077"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "v-students" }
const _hoisted_2 = { class: "v-students__box" }
const _hoisted_3 = { class: "v-students__box__grid m-with-gutter--column m-with-gutter--row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_resources_item = _resolveComponent("resources-item")!
  const _component_page_view = _resolveComponent("page-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_page_view, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.studentsItems)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.studentsItems, (item) => {
                return (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_resources_item, {
                    class: "v-students__box__grid__item",
                    data: item
                  }, null, 8, ["data"])
                ]))
              }), 256))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ]))
}